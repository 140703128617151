import madad from "../../assets/logo/madad-black.png";

const Logo = () => {
  return (
    <img
      src={madad}
      alt="Madad logo"
      title="Madad - Brought to you by The Interest Free Loans Company"
      className="argid-logo"
      style={{ margin: "0 auto" }}
    />
  );
};

export default Logo;
