import React from "react";
import Logo from "../Logo/Logo";
import { useNavigate } from "react-router-dom";

const TransparentHeader = () => {
  let navigate = useNavigate();

  const applyHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <>
      <header className="text-gray-600 text-xl body-font absolute z-30 w-full">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <div
            className="flex title-font font-medium items-center text-gray-900 mt-4 md:mb-0"
            style={{ width: "250px" }}
          >
            <a href="/">
              <Logo />
            </a>
          </div>
          <nav className="md:ml-auto md:mr-auto flex-wrap items-center text-base justify-center hidden md:flex">
            {/* <a className="mr-5 hover:text-gray-900 cursor-pointer">About</a>
          <a className="mr-5 hover:text-gray-900 cursor-pointer">How it works</a>
          <a className="mr-5 hover:text-gray-900 cursor-pointer">
            Contact &amp; Help
          </a>
          <a className="mr-5 hover:text-gray-900">Fourth Link</a> */}
          </nav>
          <button
            className="items-center bg-gray-100 border-0 py-1 pb-2 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0 hidden lg:inline-flex"
            onClick={applyHandler}
          >
            Apply for a loan or grant
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </header>
    </>
  );
};

export default TransparentHeader;
