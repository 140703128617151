import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const FeatureBlock = () => {
  let navigate = useNavigate();

  const applyHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Madad's alternative lending
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            Through Madad's partners, you can get an alternative to pay-day
            lending, improve your credit rating and can get special deals on
            white goods and house repairs. We're one of the UK's first
            altruistic lenders whose aim is to improve the life of the
            under-banked and under-served.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-purple-500 inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-indigo-500 mb-5 flex-shrink-0">
              <FontAwesomeIcon
                icon="fa-handshake"
                className="text-purple-600 text-2xl"
                title="Find out more this way"
                alt="Find out more this way"
              />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Loans
              </h2>
              <p class="leading-relaxed text-base">
                We can offer through{" "}
                <a
                  href="https://zeropa.co.uk?ref=madad"
                  className="underline text-indigo-600"
                  title="Link to ZeroPA our finance provider"
                >
                  ZeroPA
                </a>
                , interest free loans between &pound;50 and &pound;150 over 1
                week to 52 weeks, in up to 12 instalments. In some circumstances
                Zeropa may ask that you have a referral from a Citizens Advice
                Bureau, Credit Union, Food Bank, School, Place of Worship or
                other Community Group.{" "}
                {/* <span class="text-purple-600">Loans to launch in 2024!</span> */}
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-100 text-indigo-500 mb-5 flex-shrink-0">
              <FontAwesomeIcon
                icon="fa-dove"
                className="text-purple-600 text-2xl"
                title="Find out more this way"
                alt="Find out more this way"
              />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Grants
              </h2>
              <p className="leading-relaxed text-base">
                Our partners can offer small grants up to &pound;50 to help you
                through any current financial crisis. You need to show that you
                have been referred by a credit union, citizen advice bureau,
                food bank, church, mosque, temple or gurdwara.
              </p>
              <a
                href="/grants"
                className="mt-3 text-purple-500 inline-flex items-center cursor-pointer text-base"
              >
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <button
          className="flex mx-auto mt-16 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
          onClick={applyHandler}
        >
          Apply for a loan or grant
        </button>
      </div>
    </section>
  );
};

export default FeatureBlock;
