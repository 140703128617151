import Header from "../components/Header/Header";
import yay from "../assets/yay.jpg";
import Footer from "../components/Footer/Footer";

const Terms = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yay} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            Terms &amp; Conditions
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block">
            The legal stuff
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            1. General
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                1.1{" "}
                <span className="ml-8">
                  The terms "we", "us", or "our", refer to Madad (t/a ZeroPA
                  Madad UK Ltd).
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                1.2{" "}
                <span className="ml-8">
                  ZeroPA (Madad) CIC (Company No. 13285651) Ltd is registered in
                  England and Wales . Registered office: Wenta Business Centre,
                  Colne Way, Watford, England WD24 7ND.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                1.3{" "}
                <span className="ml-8">
                  These terms and conditions have no fixed duration.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            2. Accessing our site
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                2.1{" "}
                <span className="ml-8">
                  Access to our site is permitted on a temporary basis, and we
                  reserve the right to withdraw, suspend, amend the service we
                  provide on our site without notice (see below) or close it
                  indefinitely. We will not be liable if for any reason our site
                  is unavailable at any time or for any period.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                2.2{" "}
                <span className="ml-8">
                  From time to time, we may restrict access to some parts of our
                  site, or our entire site, to users who have registered with
                  us.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                2.3{" "}
                <span className="ml-8">
                  If you choose, or you are provided with, a user identification
                  code, password or any other piece of information as part of
                  our security procedures, you must treat such information as
                  confidential, and you must not disclose it to any third party.
                  We have the right to disable any user identification code or
                  password, whether chosen by you or allocated by us, at any
                  time, if in our opinion you have failed to comply with any of
                  the provisions of these terms and conditions.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                2.4{" "}
                <span className="ml-8">
                  You are responsible for making all arrangements necessary for
                  you to have access to our site. You are also responsible for
                  ensuring that all persons who access our site through your
                  internet connection are aware of these terms, and that they
                  comply with them.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                2.5{" "}
                <span className="ml-8">
                  We process information about you in accordance with our
                  privacy policy.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                2.6{" "}
                <span className="ml-8">
                  We aim to update our site regularly, and may change the
                  content at any time. Any of the material on our site may be
                  out of date at any given time, and we are under no obligation
                  to update such material.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                2.7{" "}
                <span className="ml-8">
                  We try to meet highest standards of accessibility, but cannot
                  guarantee that this site will be compatible with all hardware
                  and software that may be used by visitors to the site.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            3. Materials
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                3.1{" "}
                <span className="ml-8">
                  All copyrights, trademarks, patents, design rights, and other
                  intellectual property rights relating to our site and the
                  materials published on it (including, but not limited to, the
                  underlying software, the design, graphics, layout, feel, and
                  structure of our sites) will be and remain the sole property
                  of us or our licensors. Those works are protected by copyright
                  laws and treaties around the world. All such rights are
                  reserved.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                3.2{" "}
                <span className="ml-8">
                  You may view, use, download, print off one copy and store the
                  material on this site for personal use only and you may draw
                  the attention of others within your organisation to material
                  posted on our site. Commercial use is not permitted unless you
                  have obtained a licence to use materials on our site for
                  commercial purposes. Redistributing, republishing, copying,
                  adapting the paper or digital copies of any materials you have
                  printed off or downloaded in any way or otherwise making
                  material on this site available to third parties is
                  prohibited. You must not use any illustrations, photographs,
                  video or audio sequences or any graphics separately from any
                  accompanying text.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                3.3{" "}
                <span className="ml-8">
                  Our status (and that of any identified contributors) as the
                  authors of material on our site must always be acknowledged.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                3.4{" "}
                <span className="ml-8">
                  If you print off, copy or download any part of our site in
                  breach of these terms of use, your right to use our site will
                  cease immediately and you must, at our option, return or
                  destroy any copies of the materials you have made.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                3.5{" "}
                <span className="ml-8">
                  The information in this website is given in good faith and for
                  general information and interest only. It is subject to change
                  without notice. We make every effort to ensure that the
                  information on our websites is correct but we cannot guarantee
                  that it is 100% free of inaccuracies, errors and omissions.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            4. Our Liability
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                4.1{" "}
                <span className="ml-8">
                  Users of this site are responsible for any decisions they take
                  based on information provided on the site and commentary and
                  other materials on our site are not intended to amount to
                  advice on which reliance should be placed. You should consult
                  your professional advisers before taking any action based on
                  the content of our site. We disclaim all responsibility and
                  liability (as far as legally permitted) for any loss (directly
                  or indirectly) arising from using or relying on information or
                  links included in this site.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                4.2{" "}
                <span className="ml-8">
                  Our sites may contain links to other sites, which are provided
                  for your information only. The contents of those sites or
                  resources are outside our control and are not covered by these
                  terms and conditions. If you access other sites using the
                  links provided, we accept no responsibility for the content of
                  those sites, for the way in which they deal with you or use
                  any information they may acquire about you or for any loss or
                  damage that may arise from your use of them.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                4.3{" "}
                <span className="ml-8">
                  The material displayed on our site is provided without any
                  representations, guarantees, conditions or warranties as to
                  its accuracy, completeness or that the content is up-to-date.
                  To the extent permitted by law, we, other members of our group
                  of companies and third parties connected to us hereby
                  expressly exclude:
                </span>
                <ol className="ml-8">
                  <li>
                    all conditions, warranties and other terms which might
                    otherwise be implied by statute, common law or the law of
                    equity;
                  </li>
                  <li>
                    any liability for any direct, indirect or consequential loss
                    or damage incurred by any user in connection with our site
                    or in connection with the use, inability to use, or results
                    of the use of our site, any websites linked to it and any
                    materials posted on it, includind but not limited to: loss
                    of income or revenue; b) loss of business, loss of business
                    opportunity or other economic loss; c) loss of profits or
                    contracts; d) loss of anticipated savings; e) loss or
                    corruption of data; f) loss of goodwill; g) business
                    interruption, wasted management or office time; and in all
                    the above cases, whether caused by tort (including
                    negligence), breach of contract or otherwise, even if
                    foreseeable.
                  </li>
                </ol>
              </li>
              <li class="leading-relaxed text-sm">
                4.4{" "}
                <span className="ml-8">
                  These terms and conditions do not exclude our liability (if
                  any) to you for personal injury or death resulting from our
                  negligence, or our liability for fraud, nor any other
                  liability which cannot be excluded or limited under applicable
                  law and do not affect your statutory rights.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                4.5{" "}
                <span className="ml-8">
                  Arrangements made between you and any third party named or
                  referred to on the site are entirely at your sole risk and
                  responsibility.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            5. Your Liability
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                5.1{" "}
                <span className="ml-8">
                  You shall compensate us in full if we incur any losses or if
                  any claims or legal proceedings are brought or threatened
                  against us by any other person arising from your use of our
                  website, as well as any information uploaded by you on our
                  site.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                5.2{" "}
                <span className="ml-8">
                  You agree to indemnify and hold us harmless from any
                  liability, loss, claim, and expense (including reasonable
                  legal fees) related to any breach by you of these terms and
                  conditions or arising from your use of our site, including any
                  information uploaded by you on our site.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            6. Uploading material
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                6.1{" "}
                <span className="ml-8">
                  Whenever you make use of a feature that allows you to upload
                  material to our site, or to make contact with other users of
                  our site, you must comply with the content standards set out
                  in our acceptable use policy. You warrant that any such
                  contribution does comply with those standards, and you
                  indemnify us for any breach of that warranty.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                6.2{" "}
                <span className="ml-8">
                  We will not be responsible, or liable to any third party, for
                  the content or accuracy of any materials posted by you or any
                  other user of our site. Such information has not been verified
                  or approved by us nor represent our views and values.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                6.3{" "}
                <span className="ml-8">
                  We have the right to remove any material or posting you make
                  on our site if, in our opinion, such material does not comply
                  with the content standards set out in our acceptable use
                  policy.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                6.4{" "}
                <span className="ml-8">
                  If you upload any material on our site that is in breach of
                  any intellectual property or privacy rights, we have the right
                  to disclose your identity to the third party claiming such
                  breach.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            7. Viruses, hacking &amp; other offences
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                7.1{" "}
                <span className="ml-8">
                  You must not misuse our site by knowingly introducing viruses,
                  Trojans, worms, logic bombs or other material which is
                  malicious or technologically harmful. You must not attempt to
                  gain unauthorised access to our site, the server on which our
                  site is stored or any server, computer or database connected
                  to our site. You must not attack our site via a
                  denial-of-service attack or a distributed denial-of service
                  attack.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                7.2{" "}
                <span className="ml-8">
                  By breaching this provision, you would commit a criminal
                  offence under the Computer Misuse Act 1990. We will report any
                  such breach to the relevant law enforcement authorities and we
                  will co-operate with those authorities by disclosing your
                  identity to them. In the event of such a breach, your right to
                  use our site will cease immediately.
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                7.3{" "}
                <span className="ml-8">
                  We will not be liable for any loss or damage caused by a
                  distributed denial-of-service attack, viruses or other
                  technologically harmful material that may infect your computer
                  equipment, computer programs, data or other proprietary
                  material due to your use of our site or to your downloading of
                  any material posted on it, or on any website linked to it.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            8. Jurisdiction and applicable law
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                8.1{" "}
                <span className="ml-8">
                  The English courts will have exclusive jurisdiction over any
                  claim arising from, or related to, a visit to our site, these
                  terms of use and their subject matter (including any
                  non-contractual obligations arising out of or in connection
                  with it).
                </span>
              </li>
              <li class="leading-relaxed text-sm">
                8.2{" "}
                <span className="ml-8">
                  These terms of use and any dispute or claim arising out of or
                  in connection with them or their subject matter or formation
                  (including non-contractual disputes or claims) shall be
                  governed by and construed in accordance with the law of
                  England and Wales.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            9. Variations
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <ol>
              <li class="leading-relaxed text-sm">
                9.1{" "}
                <span className="ml-8">
                  We reserve the right to change these terms and conditions from
                  time to time, and the amended terms will be posted on this
                  website. You are expected to check this page from time to time
                  to take notice of any changes we make, as they are binding on
                  you. Some of the provisions contained in these terms of use
                  may also be superseded by provisions or notices published
                  elsewhere on our site.
                </span>
              </li>
            </ol>
          </div>
          <p className="text-base">Last updated August 2022.</p>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Terms;
