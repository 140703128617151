import Header from "../components/Header/Header";
import yay from "../assets/yay.jpg";
import Footer from "../components/Footer/Footer";

const About = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yay} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            About Madad
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block">
            An alternative lender
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            Disrupting, educating &amp; lending
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <p class="leading-relaxed text-base">
              Madad is a fintech disruptor that offers financial education and
              tools and crucially interest free loans to the financially
              disadvantaged and vulnerable through donations from our
              supporters.
            </p>
            <p class="leading-relaxed text-base">
              Madad can offer interest free loans from ZeroPA between &pound;50
              and &pound;150 over 1 week to 52 weeks in up to 12 instalments.
              You can apply online and receive a decision and your funds within
              5 days.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
