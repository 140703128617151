import Header from "../components/Header/Header";
import heyo from "../assets/heyo.jpg";
import Footer from "../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import help from "../assets/help.jpg";

const HowItWorks = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={heyo} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            How it works
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block">
            Lending at 0%
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-8 md:w-2/5 leading-none">
            Our process
          </h2>
          <div class="flex flex-wrap w-full">
            <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                  <FontAwesomeIcon
                    icon="fa-signature"
                    className="text-white text-lg"
                  />
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    STEP 1
                  </h2>
                  <p class="leading-relaxed">
                    We attract donors to support Madad and amazing altruistic
                    lenders to lend to Madad for no return.
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                  <FontAwesomeIcon
                    icon="fa-puzzle-piece"
                    className="text-white text-lg"
                  />
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    STEP 2
                  </h2>
                  <p class="leading-relaxed">
                    We introduce to ZeroPA . They do not offer cash loans but
                    rather vouchers and e-cards to spend with retailers,
                    manufacturers and utility companies.
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                  <FontAwesomeIcon
                    icon="fa-chart-pie"
                    className="text-white text-lg"
                  />
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    STEP 3
                  </h2>
                  <p class="leading-relaxed">
                    ZeroPA's running costs are covered through revenue share
                    with the retailers, white goods manufacturers, household
                    repairers and partnerships with financial institutions.
                  </p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                  <FontAwesomeIcon
                    icon="fa-place-of-worship"
                    className="text-white text-lg"
                  />
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    STEP 4
                  </h2>
                  <p class="leading-relaxed">
                    We want borrowers to improve their financial literacy and
                    help them through ZeroPA interest-free loans. Because we
                    want to target lending to those most in need, ZeroPA only
                    lend to those who have been referred to us by a partner,
                    such as a Credit Union, Citizens Advice Bureau, Place of
                    Worship, School or Food Bank.
                  </p>
                </div>
              </div>
              <div class="flex relative">
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                  <FontAwesomeIcon
                    icon="fa-handshake"
                    className="text-white text-lg"
                  />
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                    FINISH
                  </h2>
                  <p class="leading-relaxed">
                    By eliminating interest we make sure ZeroPA loans are
                    affordable and over time help borrowers out of their
                    financial situation through learning to manage their money
                    better. If ZeroPA feel a loan is not appropriate, we may
                    direct customers to our partner ZeroPA Madad CIC to offer an
                    emergency grant of up to &pound;50 to help with food,
                    utilities, clothing or furniture.
                  </p>
                </div>
              </div>
            </div>
            <img
              class="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
              src={help}
              alt="A person supporting someone else indoors and holding each others hands"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HowItWorks;
