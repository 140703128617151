import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import yep from "../assets/yep.jpg";
import Footer from "../components/Footer/Footer";
import ApplicationTimeline from "../components/ApplicationTimeline/ApplicationTimeline";
import { API_URL } from "../core/constants";
import Toast from "../components/Toast/Toast";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import SizedConfetti from "../components/SizedConfetti/SizedConfetti";

const Finished = () => {
  const [loading, setLoading] = useState(true);
  const [loanAmount, setLoanAmount] = useState(0);
  const [name, setName] = useState("");
  const [errored, setErrored] = useState(false);
  const [reference, setReference] = useState("");

  let navigate = useNavigate();
  let { id, hash } = useParams();

  if (!id || !hash) {
    navigate("/no-application", { replace: false });
  }

  useEffect(() => {
    const fetchApplication = () => {
      setLoading(true);
      axios
        .get(`${API_URL}/application/${id}/${hash}`)
        .then(function (response) {
          console.log("response", response);
          setLoanAmount(response.data.body.application.amount);
          setName(response.data.body.application.firstName);
          setReference(response.data.body.application.reference);
          setLoading(false);
          // window.analytics.track(`apply.complete`, {
          //   id: id,
          //   hash: hash,
          // });
        })
        .catch(function (error) {
          Toast.popError(
            "Oh no, something has gone wrong. Please try again or contact us."
          );
          console.log(error);
          setLoading(false);
          setErrored(true);
        });
    };

    fetchApplication();
  }, [id, hash]);

  const renderError = () => {
    return (
      <section>
        <div className="p-8 bg-slate-100 rounded-lg">
          <div className="w-full">
            <span className="block text-gray-900 text-center text-base">
              We're sorry, something has gone wrong and we can't get that
              application. Please check the details and try again, or contact us
              if the problem persists.
            </span>
          </div>
        </div>
      </section>
    );
  };

  const { width } = useWindowSize();
  const height = document.body.scrollHeight;

  return (
    <>
      <SizedConfetti width={width} height={height} recycle={false} />
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yep} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-2xl font-medium text-gray-900">
            Apply for finance
          </h1>
          <span className="mb-4 leading-tight text-lg drop-shadow block">
            Let's find you the right help
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container pt-16 pb-8 px-8 sm:px-0 mx-auto flex flex-wrap">
          <p class="leading-relaxed text-base ml-8">
            Our finance application process couldn't be simpler, if you let us
            know what you need and we'll help find the right product for you
            (loan or grant). Before you begin, please have your referral details
            and necessary documents to prove your identity (like a driving
            licence, council tax bill or utility bill). We may ask you some
            questions about your income and expenditure, but we only ask the
            minimum questions we need.
          </p>
        </div>
      </section>

      <section class="text-gray-600 body-font mt-8">
        <div class="container mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="hidden md:block md:w-1/3 pr-10 text-base">
              <ApplicationTimeline atStep={4} />
            </div>
            <div className="w-full md:w-2/3">
              {errored ? (
                renderError()
              ) : loading ? (
                <section>
                  <div className="p-8 bg-slate-100 rounded-lg">
                    <div className="w-full">
                      <span className="block text-gray-900 text-center text-2xl animate-pulse">
                        We won't be a moment...
                      </span>
                    </div>
                  </div>
                </section>
              ) : (
                <section>
                  <div className="mb-8 text-base">
                    <div className="bg-indigo-600 rounded">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <p className="ml-3 font-medium text-white truncate">
                              <span className="inline">
                                Thank you {name}! Your application of &pound;
                                {loanAmount} financing has been submitted.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-8 bg-slate-100 rounded-lg">
                    <h3 class="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
                      Thank you! You're all set.
                    </h3>
                    <p className="block">
                      Your loan application is on its way to us and we'll be
                      back in touch within 5-days. Your reference for your
                      application is{" "}
                      <span className="text-indigo-700 font-bold">
                        {reference}
                      </span>
                      , you can use this whenever you contact us to find your
                      details. We've sent you an email with your reference also.
                    </p>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Finished;
