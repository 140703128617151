import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faDove,
  faHandshake,
  faStore,
  faBolt,
  faBullhorn,
  faPlaceOfWorship,
  faChartPie,
  faPuzzlePiece,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";

import Home from "./views/Home";
import ThankYou from "./views/ThankYou";
import FourOhFour from "./views/FourOhFour";
import Loans from "./views/Loans";
import Grants from "./views/Grants";
import HowItWorks from "./views/HowItWorks";
import Contact from "./views/Contact";
import About from "./views/About";
import Apply from "./views/Apply";
import Terms from "./views/Terms";
import DocumentUpload from "./views/DocumentUpload";
import NoApplicationFound from "./views/NoApplicationFound";
import Finished from "./views/Finished";
import React from "react";
import Donors from "./views/Donors";
import Benefits from "./views/Benefits";

import "./styles/app.css";

library.add(
  faChevronDown,
  faDove,
  faHandshake,
  faStore,
  faBolt,
  faBullhorn,
  faPlaceOfWorship,
  faChartPie,
  faPuzzlePiece,
  faSignature
);

export default function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
        rtl={false}
      />
      <Helmet>
        <title>Madad - Interest Free Loans</title>
        <meta
          name="description"
          content="Madad provides interest free loans and is here to disrupt, educate and lend"
        />
      </Helmet>

      <Routes>
        <Route path="*" element={<FourOhFour />} />
        <Route path="/" element={<Home />} />
        <Route path="/thanks" element={<ThankYou />} />
        <Route path="/loans" element={<Loans />} />
        <Route path="/grants" element={<Grants />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/apply/:id/:hash" element={<DocumentUpload />} />
        <Route path="/apply/:id/:hash/finished" element={<Finished />} />
        <Route path="/no-application" element={<NoApplicationFound />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/donors" element={<Donors />} />
        <Route path="/benefits" element={<Benefits />} />
      </Routes>
    </>
  );
}
