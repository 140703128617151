import Header from "../components/Header/Header";
import yay from "../assets/yay.jpg";
import Footer from "../components/Footer/Footer";

const Benefits = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yay} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            Benefits Calculator
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block">
            Are you missing out on benefits?
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            Check your eligibility
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <p class="leading-relaxed text-base">
              Over 8 million UK households are missing &pound;16 billion in
              benefits. We work in Partnership with{" "}
              <a href="https://inbest.ai" rel="noreferrer" target="_blank">
                InBest
              </a>{" "}
              to help ensure that you receive all of the benefits that you're
              entitled to.
            </p>
            <p class="leading-relaxed text-base">
              The Benefits Calculator will help you to understand your
              entitlements to Universal Credit, Pension Credit, Child Benefit,
              Council Tax Reduction and Help to Save.
            </p>
            <div className="mt-8 rounded">
              <iframe
                id="inbest_iframe"
                src="https://benefits.inbest.ai/iframe/zeropa"
                width="100%"
                frameborder="0"
                className="w-full h-[1050px] border shadow-md border-grey-200 rounded-lg"
                title="benefits-inbest"
                data-uw-rm-iframe="gn"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Benefits;
