import Header from "../components/Header/Header";
import invest from "../assets/invest.jpg";
import Footer from "../components/Footer/Footer";

const Donors = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img
          src={invest}
          className="min-w-full object-cover object-top"
          alt=""
        />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 className="title-font sm:text-4xl text-3xl font-medium text-gray-900">
            Donors &amp; Lenders
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block">
            Make a positive difference to others
          </span>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 className="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            Donors &amp; Lenders
          </h2>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base mb-4">
              If you'd like to donate to Madad UK (MUK) please email{" "}
              <a
                href="mailto:donors@madad.co.uk"
                className="text-indigo-600 underline"
              >
                donors@madad.co.uk
              </a>
              .
            </p>
            <p className="leading-relaxed text-base mb-4">
              If you'd like to become a lender, through ZeroPA, to help people
              in need please email{" "}
              <a
                href="mailto:lenders@madad.co.uk"
                className="text-indigo-600 underline"
              >
                lenders@madad.co.uk
              </a>
              .
            </p>
            <p className="leading-relaxed text-base mb-4">
              You can read more about ZeroPA our lending and technology partner{" "}
              <a
                href="https://app.newspage.media/zeropa-madad-uk-ltd"
                className="text-indigo-600 underline"
              >
                https://app.newspage.media/zeropa-madad-uk-ltd
              </a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Donors;
